import Footer from "components/common/Footer";
import { Header } from "./Header";

const Layout = ({ children, hideHeader, hideFooter }) => {
  return (
    <>
      {!hideHeader && <Header />}

      <main className={`flex-1 h-screen ${hideHeader ? "pt-0" : "pt-[74px]"}`}>
        {children}
        {!hideFooter && <Footer />}
      </main>
    </>
  );
};

export default Layout;
