import { getRecoverOrder } from "@/services/OrderService";
import updateHeaderCartQnty from "@/utils/updateHeaderCartQnty";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const ShoppingCartButton = () => {
  const router = useRouter();

  useEffect(() => {
    getRecoverOrder()
      .then((order) => updateHeaderCartQnty(order))
      .catch(() => undefined);
  }, []);

  return (
    <button
      aria-label="Cart Button"
      className="uppercase relative text-gray-400 hover:text-gray-700 font-bold transition-colors duration-100 text-lg"
      onClick={() => {
        router.push(`/cart`);
      }}
    >
      <ShoppingCartIcon className="lg:h-7 lg:w-7 h-6 w-6" />

      <div
        id="cart-items"
        className="absolute -top-2 -right-2 rounded-full text-white text-xs font-normal bg-blue-500 h-5 w-5 flex
               items-center justify-center"
      >
        0
      </div>
    </button>
  );
};
