import { useRouter } from "next/router";
import Link from "next/link";
import { useState } from "react";
import MmfLogo from "assets/svg/MmfLogo";
import { SideMenu } from "./SideMenu";
import HeadlessModal from "../HeadlessModal";
import { LoggedButtons } from "./LoggedButtons";
import { PopoverNavigation } from "./PopoverNavigation";
import { navigation } from "@/constants/header";
import PaintBrush from "assets/svg/PaintBrush.svg";
import { socials } from "@/constants/footer";
import { ShoppingCartButton } from "./ShoppingCartButton";

export const Header = () => {
  const router = useRouter();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onLogin = () => {
    if (["/create/[orderId]/[designId]/lasso"].includes(router.pathname))
      setShowConfirmationModal("/auth/signin");
    else router.push("/auth/signin");
  };

  return (
    <>
      <div className="fixed bg-white w-full z-[999]">
        <nav className="max-w-[1142px] z-[1000] flex lg:justify-between justify-center items-center mx-auto h-[91px] py-4 px-5">
          <section className="hidden lg:flex items-center justify-left space-x-6">
            <Link href="/" className="text-gray-200">
              <a
                className="flex items-center"
                aria-label="Make My Freshener Logo"
              >
                <MmfLogo alt={`Make My Freshener`} width={96} />
              </a>
            </Link>
            <section className="flex space-x-6">
              <PopoverNavigation title="Shop" items={navigation.shop} />
              <PopoverNavigation title="Learn" items={navigation.learn} />
              <PopoverNavigation title="Support" items={navigation.support} />
              <PopoverNavigation title="Company" items={navigation.company}>
                <ul className="flex space-x-4 px-5 mt-2">
                  {socials.map(({ Icon, ...social }) => (
                    <li
                      key={social.id}
                      id={social.id}
                      className="hover:-translate-y-0.5 transition ease-in duration-200"
                    >
                      <a href={social.href} target="_blank">
                        <Icon alt={social.label} height={24} width={24} />
                      </a>
                    </li>
                  ))}
                </ul>
              </PopoverNavigation>
            </section>
          </section>
          <section className="flex items-center lg:space-x-8 lg:justify-center justify-between lg:w-fit w-full">
            <div className="flex items-center space-x-4">
              <SideMenu />

              <Link href="/" className="text-gray-200">
                <a
                  className="lg:hidden flex items-center"
                  aria-label="Make My Freshener Logo"
                >
                  <MmfLogo alt={`Make My Freshener`} width={80} />
                </a>
              </Link>
            </div>
            <div className="flex items-center space-x-4 justify-between w-fit">
              <LoggedButtons onLogin={onLogin} />
              <ShoppingCartButton />
              <Link href="/shop">
                <a className="whitespace-nowrap blue-button rounded-[18px] font-semibold lg:px-5 lg:py-2.5 px-3 py-2">
                  <PaintBrush
                    height={20}
                    width={20}
                    className="mt-[3px] hidden lg:block "
                  />
                  <PaintBrush
                    height={14}
                    width={14}
                    className="mt-[5px] lg:hidden"
                  />
                  <span className="ml-2 hidden lg:block text-xl">
                    Design Now
                  </span>
                  <span className="ml-2 lg:hidden text-base">Design</span>
                </a>
              </Link>
            </div>
          </section>
        </nav>
      </div>
      <HeadlessModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        variant="danger"
        title="Are you sure?"
        text="You can sign in after creating your custom freshener! If you leave the page now, you might need to restart the process."
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          router.push(showConfirmationModal);
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};
