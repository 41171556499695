import api from "services/Api";
import { startSpan } from "lib/lazySentry";
import { getUTMParams } from "utils/storage";

export const getOrderItems = async (id) => {
  const resp = await api.get(`/v2/store/orders/${id}/items`);

  return resp.data;
};

export const getOrderRushOptions = async (id) => {
  const resp = await api.get(`v2/store/orders/${id}/rush `);

  return Array.isArray(resp.data) ? resp.data : [];
};

export const putOrderRushOption = async ({ id, rush }) => {
  const resp = await api.put(`v2/store/orders/${id}/rush `, { rush });

  return resp.data;
};

export const putOrderCouponCode = async ({ id, coupon_code }) => {
  const resp = await api.put(`v2/store/orders/${id}/coupon `, { coupon_code });

  return resp.data;
};

export const removeOrderCouponCode = async ({ id, coupon_code }) => {
  const resp = await api.delete(`v2/store/orders/${id}/coupon `, {
    data: { coupon_code },
  });

  return resp.data;
};

export const getOrdersList = async () => {
  const resp = await api.get(`/v2/store/orders/`);

  return resp.data;
};

export const getRecoverOrder = async () => {
  const resp = await api.get(`/v2/store/orders/recover`);

  return resp.data;
};

export const getOrderConfirmation = async ({ id }) => {
  const resp = await api.get(`v2/store/orders/${id}/confirmation`);

  return resp.data;
};

export const createOrder = async ({ design, freshener }) => {
  const utmData = getUTMParams();
  const resp = await api.post("/v2/store/orders/create", {
    design,
    freshener,
    ...(utmData && {
      utm_tracking: {
        source: utmData.utm_source || "",
        medium: utmData.utm_medium || "",
        campaign: utmData.utm_campaign || "",
        term: utmData.utm_term || "",
        content: utmData.utm_content || "",
      },
    }),
  });

  return resp.data;
};

export const createOrderItem = async ({
  order,
  design,
  attributes,
  quantity,
  description,
  hcard_design,
}) => {
  return startSpan({ name: "POST create_item", attributes: { quantity } }, () =>
    api
      .post(`/v2/store/orders/${order}/create_item`, {
        design,
        attributes,
        quantity,
        ...(hcard_design && { hcard_design: { id: hcard_design } }),
        ...(description && { description }),
      })
      .then((res) => res.data)
  );
};

export const deleteOrderItem = async ({ id }) => {
  return startSpan({ name: "DELETE item" }, () =>
    api.delete(`/v2/store/items/${id}/delete`).then((res) => res.data)
  );
};

export const putOrderItem = async ({
  id,
  attributes,
  quantity,
  description,
  hcard_design,
}) => {
  const resp = await api.put(`/v2/store/items/${id}/update`, {
    ...(description && { description }),
    ...(attributes && { attributes }),
    ...(quantity && { quantity }),
    ...(hcard_design && { hcard_design }),
  });

  return resp.data;
};

export const createDesign = async ({ shape, name }) => {
  const resp = await api.post("/v2/store/designs/create", {
    shape,
    name,
  });

  return resp.data;
};

export const setOrderRejection = async (order) => {
  const resp = await api.put(`/v2/store/orders/${order}/reject`);

  return resp.data;
};

export const checkout = async (order) => {
  const resp = await api.post(`/v2/store/orders/${order}/checkout`);

  return resp.data;
};

export const attachOrderToCustomer = async (order) => {
  const resp = await api.put(`/v2/store/orders/${order}/customer`);

  return resp.data;
};

export const addToCart = async (currentOrderId, targetOrderId) => {
  const resp = await api.post(`/v2/store/orders/add_to_cart`, {
    current_order: currentOrderId,
    target_order: targetOrderId,
  });

  return resp.data;
};
