import Link from "next/link";
import { ChevronDown } from "lucide-react";
import { useState } from "react";

const FooterNavSection = ({ title, items }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className="relative text-black bg-white sm:bg-transparent w-full sm:w-fit rounded-[20px]"
      aria-label="Footer"
    >
      <header
        onClick={() => setExpanded(!expanded)}
        className="relative w-full px-4 py-4 sm:p-0"
      >
        <h4 className="text-lg font-semibold">{title}</h4>
        <ChevronDown
          className={`h-6 w-6 absolute right-4 top-4 sm:hidden ${
            expanded ? "rotate-180" : ""
          }`}
        />
      </header>

      <ul
        className={`px-4 sm:px-0 py-4 ${
          expanded ? "block" : "hidden sm:block"
        }`}
      >
        {items.map((item) => (
          <li key={item.name} className={`flex flex-col `}>
            <Link href={item.href} {...(!item.prefetch && { prefetch: false })}>
              <a className="cursor-pointer text-base text-black hover:text-gray-700 mb-2">
                {item.name}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default FooterNavSection;
